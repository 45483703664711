.login-container {
  max-width: 400px;
  margin: 4rem auto;
  padding: 3rem;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

.google-login-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.google-login-button {
  width: 100%;
  max-width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #4285F4;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.google-login-button img {
  margin-right: 8px;
}

.ant-input {
  height: 45px;
  border-radius: 5px;
  font-size: 16px;
}

.ant-input-password {
  height: 45px;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
}

.register-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.register-link a {
  color: #007bff;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}
