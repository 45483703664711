/* Support Page */
.support-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(135deg, #f4f6f9, #f0f3f5);
    border-radius: 15px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .support-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 1.5px;
  }
  
  .support-search {
    margin-bottom: 30px;
  }
  
  .support-articles {
    margin-bottom: 40px;
  }
  
  .support-article-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .support-article-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
  
  .support-contact {
    text-align: center;
  }
  
  .support-contact h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-button {
    width: 100%;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #ffbb33, #ff9f00);
    border: none;
    color: #fff;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 20px;
  }
  
  .contact-button:hover {
    background-color: #ff8c00;
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .support-title {
      font-size: 28px;
    }
  
    .support-container {
      padding: 15px;
    }
  
    .support-article-card {
      padding: 15px;
    }
  
    .contact-button {
      font-size: 14px;
      padding: 10px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .support-title {
      font-size: 24px;
    }
  
    .support-container {
      padding: 10px;
    }
  
    .support-article-card {
      padding: 10px;
    }
  
    .contact-button {
      font-size: 12px;
      padding: 8px 12px;
    }
  }
  