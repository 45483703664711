/* Bottom Navigation Bar */
.bottom-nav {
    display: none; /* Hide by default */
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #111;
    padding: 10px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .nav-item {
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  
  .nav-icon {
    font-size: 24px;
    color: #ffc107;
    margin-bottom: 5px;
  }
  
  .nav-item p {
    margin: 0;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 480px) {
    .nav-icon {
      font-size: 20px;
    }
    
    .nav-item {
      font-size: 12px;
    }

    
  }
  /* Show bottom navigation only on screens smaller than 768px */
@media (max-width: 768px) {
    .bottom-nav {
      display: flex; /* Show on mobile devices */
    }
  }