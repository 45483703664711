/* Background and layout styling */
.terms-layout {
  background-color: #1c1c1c; /* Darker background for better contrast */
}

.terms-content {
  padding: 40px 20px;
  max-width: 900px; /* Slightly wider content area */
  margin: 20px auto;
  background-color: #f5f5f5; /* Slightly lighter gray for content */
  border-radius: 12px; /* Softer rounded corners */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow for better depth */
}

/* Typography styling */
.terms-title {
  color: #000000 !important; /* Yellow color for the main title */
  text-align: center;
  font-weight: 700; /* Bolder title for emphasis */
  font-size: 32px; /* Larger title */
  margin-bottom: 20px; /* More space below the title */
}

.terms-subtitle {
  margin-top: 40px; /* More space above subtitles */
  color: #ffcc00; /* Yellow color for section titles */
  font-weight: 600; /* Bolder section titles */
}

.terms-paragraph {
  font-size: 18px; /* Slightly larger font size */
  line-height: 1.8; /* Increased line height for readability */
  color: #000000; /* Lighter gray for text */
}

.terms-list {
  padding-left: 30px; /* More padding for list items */
  color: #000000; /* Lighter gray for list items */
  font-size: 18px; /* Consistent font size */
}

/* Link styling */
.terms-link {
  color: #ffcc00; /* Yellow color for links */
  text-decoration: underline;
}

.terms-link:hover {
  text-decoration: none; /* Remove underline on hover */
}
