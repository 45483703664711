.routes-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }
  
  .routes-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .routes-header h1 {
    font-size: 36px;
    margin-bottom: 10px;
    color: #ffbb33;
  }
  
  .routes-header p {
    font-size: 18px;
    color: #cccccc;
  }
  
  /* Search Bar */
  .route-search-bar {
    width: 100%;
    max-width: 500px;
    margin: 20px auto 40px;
    padding: 12px;
    border-radius: 30px;
    border: 2px solid #ffbb33;
    background-color: #ffffff;
    color: #000000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .route-search-bar input {
    background-color: transparent;
    color: #5c5c5c;
    border: none;
    box-shadow: none;
  }
  
  .route-search-bar::placeholder {
    color: #999999;
  }
  
  /* Grid Layout for Route Cards */
  .routes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .route-card {
    background-color: #2b2b2b;
    border-radius: 8px;
    padding: 15px;
    color: #ffbb33;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .route-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  
  .route-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100px;
  }
  
  .route-card h3 {
    font-size: 20px;
    color: #ffbb33;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  