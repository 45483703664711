/* General Styles */
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #000000; /* Black background */
}

/* Header Section */
.header-section {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #ffd700; /* Yellow background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow for contrast */
}

.header-title {
  color: #000000; /* Black text */
  margin-bottom: 16px;
}

.header-paragraph {
  color: #000000; /* Black text */
  font-size: 16px;
  line-height: 1.6;
}

/* Content Section */
.content-section {
  margin-bottom: 40px;
}

/* Form Card */
.form-card {
  background-color: #ffffff; /* White background */
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow */
  padding: 24px;
}

.contact-form .ant-form-item-label > label {
  color: #000000; /* Black label text */
  font-weight: 500;
}

.contact-form .ant-input,
.contact-form .ant-input-textarea {
  border-radius: 4px;
  border-color: #ffd700; /* Yellow border */
}

.contact-form .ant-input:hover,
.contact-form .ant-input:focus,
.contact-form .ant-input-textarea:hover,
.contact-form .ant-input-textarea:focus {
  border-color: #ffd700; /* Yellow border on focus */
}

.contact-form .ant-btn-primary {
  background-color: #ffd700; /* Yellow button */
  border-color: #ffd700; /* Yellow border */
  color: #000000; /* Black text */
  font-weight: 500;
  height: 45px;
  font-size: 16px;
}

.contact-form .ant-btn-primary:hover,
.contact-form .ant-btn-primary:focus {
  background-color: #e6c200; /* Slightly darker yellow on hover */
  border-color: #e6c200;
  color: #000000; /* Keep text black */
}

/* Details Card */
.details-card {
  background-color: #ffffff; /* White background */
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow */
  padding: 24px;
}

.contact-detail {
  display: flex;
  align-items: center;
}

.detail-icon {
  font-size: 24px;
  color: #ffd700; /* Yellow icons */
  margin-right: 16px;
}

.detail-text {
  color: #000000; /* Black text */
  font-size: 16px;
  text-decoration: none;
  word-break: break-all;
}

.detail-text:hover {
  color: #e6c200; /* Slightly darker yellow on hover */
}

.link-button {
  display: inline-block;
  padding: 10px 16px;
  background-color: #ffd700; /* Yellow button */
  color: #000000; /* Black text */
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #e6c200; /* Slightly darker yellow on hover */
  color: #000000; /* Keep text black */
}

/* Map Section */
.map-section {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow */
}

.map-container {
  width: 100%;
  height: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-container {
    padding: 20px 10px;
  }

  .header-paragraph {
    font-size: 14px;
  }

  .content-section {
    margin-bottom: 30px;
  }

  .form-card,
  .details-card {
    padding: 20px;
  }

  .detail-icon {
    font-size: 20px;
    margin-right: 12px;
  }

  .detail-text {
    font-size: 14px;
  }

  .link-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .map-section {
    height: 300px;
    margin-bottom: 20px;
  }
}
