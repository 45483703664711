.blog-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #ffc107;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.blog-header {
  text-align: center;
  margin-bottom: 40px;
}

.blog-header h1 {
  font-size: 48px;
  margin-bottom: 15px;
  color: #000000;
}

.blog-header p {
  font-size: 20px;
  color: #2a2929;
}

/* Featured Post */
.featured-post-container {
  margin-bottom: 40px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.featured-post {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  position: relative;
}

.featured-post::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
  border-radius: 12px;
}

.featured-post-content {
  color: #ffffff;
  max-width: 600px;
  z-index: 2;
}

.featured-label {
  background-color: #ffbb33;
  color: #1a1a1a;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.featured-post-content h2 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #ffbb33;
}

.featured-post-content p {
  font-size: 18px;
  color: #dddddd;
}

.read-more-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffbb33;
  color: #1a1a1a;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.read-more-button:hover {
  background-color: #e6a800;
  transform: translateY(-3px);
}

/* Blog Posts Grid */
.blog-page-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blog-posts-grid {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.blog-post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.blog-post-item img {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 2px solid #ffbb33;
}

.blog-post-item-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-post-item-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffbb33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-post-item-content p {
  font-size: 16px;
  color: #dddddd;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Sidebar */
.sidebar {
  padding: 25px;
  background-color: #2b2b2b;
  border-radius: 12px;
}

.sidebar input[type="text"] {
  border-radius: 20px;
  padding: 12px;
  border: 1px solid #ffbb33;
  background-color: #1a1a1a;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
}

.sidebar a:hover {
  color: #ffaa00;
}

/* Pagination */
.pagination-component {
  margin-top: 40px;
  text-align: center;
}

.pagination-component .ant-pagination-item {
  background-color: #2b2b2b;
  border: none;
  color: #ffffff;
}

.pagination-component .ant-pagination-item-active {
  background-color: #ffbb33;
  border-color: #ffbb33;
  color: #1a1a1a;
}

.pagination-component .ant-pagination-item:hover {
  background-color: #ffaa00;
  border-color: #ffaa00;
}




/* Mobile Styles */
@media (max-width: 768px) {
  .blog-page-content {
    flex-direction: column;
  }
  
  .blog-posts-grid {
    grid-template-columns: 1fr;
  }
  
  .featured-post {
    height: 300px;
  }
  
  .featured-post-content h2 {
    font-size: 28px;
  }
}
