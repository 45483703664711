/* BlogPostDetail.css */
.blog-post-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background-color: #1a1a1a;
  color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
}

.featured-image {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  position: relative;
  border-radius: 12px;
}

.featured-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  border-radius: 12px;
}

.blog-post-detail {
  padding: 30px;
}

.post-title {
  font-size: 22px;
  color: #ffbb33;
  /* margin-bottom: 15px; */
}

.post-date {
  font-size: 18px;
  color: #cccccc;
  margin-bottom: 30px;
}

.post-content {
  font-size: 20px;
  line-height: 1.8;
  color: #ffffff;
}



.post-author {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.author-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #ffbb33;
}

.post-author h3 {
  margin: 0;
  font-size: 26px;
  color: #ffbb33;
}

.post-author p {
  color: #cccccc;
}

.social-share {
  display: flex;
  gap: 15px;
  margin-bottom: 50px;
}

.share-button {
  background-color: #ffbb33;
  color: #1a1a1a;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.share-button:hover {
  background-color: #e6a800;
  transform: scale(1.05);
}

.related-posts {
  margin-bottom: 50px;
}

.related-posts h2 {
  font-size: 32px;
  color: #ffbb33;
  margin-bottom: 25px;
}

.related-post {
  margin-bottom: 15px;
}

.related-post a {
  color: #ffbb33;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

.related-post a:hover {
  text-decoration: underline;
}

.comments-section {
  margin-top: 50px;
  padding: 30px;
  background-color: #2b2b2b;
  border-radius: 12px;
}

.comments-section h2 {
  font-size: 32px;
  color: #ffbb33;
  margin-bottom: 25px;
}

.comments-section p {
  color: #cccccc;
  font-size: 18px;
}
