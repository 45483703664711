.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; /* Reduced margin to save space */
    background-color: #000;
    padding: 5px 10px; /* Reduced padding to make the section more compact */
}

.back-icon {
    font-size: 20px; /* Slightly reduce the size of the back icon */
    cursor: pointer;
    color: #ffa500;
}

.itinerary-text {
    color: #ffa500; /* Maintain the yellow color for text */
    font-size: 16px; /* Reduce the font size slightly */
    margin: 0 5px; /* Reduce the side margins */
    font-weight: bold;
    line-height: 1.2; /* Reduce line height to make text more compact */
    text-align: center;
}

.trip-type {
    color: #ffa500;
    font-size: 18px;
}

.date-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-item {
    text-align: center;
    margin: 10px;
    flex: 1;
}

.features-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
    flex: 1;
}

.feature-item .anticon {
    font-size: 32px;
    margin-bottom: 5px;
    color: #ffa500;
}

.vehicle-card {
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    margin-bottom: 20px;
}

.vehicle-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.vehicle-card .vehicle-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background-color: #f0f2f5;
}

.vehicle-card .car-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.vehicle-card .ant-card-body {
    padding: 20px;
}

.vehicle-card .price-title {
    color: #1890ff;
    font-weight: bold;
}

.vehicle-card .vehicle-type {
    color: #000;
    font-size: 16px;
}

.vehicle-card .vehicle-description {
    color: #555;
}

.vehicle-card .vehicle-details {
    color: #333;
}

.vehicle-card .ant-btn-primary {
    background-color: #ffa500;
    border-color: #ffa500;
    font-weight: bold;
}

.vehicle-card .ant-btn-primary:hover {
    background-color: #cc8400;
    border-color: #cc8400;
}

.limited-time-offer {
    background-color: #ff4d4f;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.countdown-timer {
    font-size: 18px;
    font-weight: bold;
    color: #ff4d4f;
}

.reviews-container {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.rating-stars {
    color: #ffcc00;
    margin-right: 5px;
}

.popular-indicator {
    background-color: #eaff8f;
    color: #389e0d;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
}

.trust-badges {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.trust-badge {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    width: 100px;
}

.trust-badge img {
    max-width: 50px;
    margin-bottom: 5px;
}

.book-now-button {
    background-color: #ffa500;
    border-color: #ffa500;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px; /* Adjust the height as needed */
    line-height: 1.2; /* Adjust line height to ensure text is vertically centered */
}

.book-now-button:hover {
    background-color: #cc8400;
    border-color: #cc8400;
}


@media (max-width: 768px) {
    .date-section, .features-section {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
    }

    .vehicle-card {
        flex-basis: 100%;
        margin: 10px 0;
    }

    .date-item, .feature-item {
        flex: 0 0 auto;
    }
}
