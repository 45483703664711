/* General Styles */
body {
  margin: 10PX;
  padding: 10;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* About Section Layout */
.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 100px;
  background: #ffc107;
  position: relative;
  overflow: hidden;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 223, 0, 1), rgba(255, 182, 0, 0.8));
  z-index: -1; /* Place it behind the content */
  transform: translateY(-20px);
  transition: transform 0.5s ease-out;
}

.about-section:hover::before {
  transform: translateY(0); /* Parallax-like effect on hover */
}


.about-content {
  max-width: 50%;
}

.about-title {
  color: #000;
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-subtitle {
  font-size: 16px;
  color: #777;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.about-text {
  font-size: 18px;
  line-height: 1.8;
  color: #555;
  margin-bottom: 30px;
}

.about-button {
  background-color: #ffc107;
  color: #000;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: #e6a800;
}

.about-image-wrapper {
  position: relative;
  max-width: 45%;
}

.about-image {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.trust-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ffcc00;
  color: #000;
  padding: 20px 60px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Benefit Section Styles */
.benefit-section {
  background-color: #000;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.benefit-header {
  margin-bottom: 50px;
}

.benefit-subtitle {
  font-size: 16px;
  color: #ffc107;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.benefit-title {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.benefit-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px; /* Added padding to ensure cards don't touch the edges */
  box-sizing: border-box; /* Ensures padding is included within the container width */
}

.benefit-card {
  background-color: #111;
  border-radius: 10px;
  padding: 20px;
  flex: 1 1 100%; /* Flex-grow, flex-shrink, and flex-basis set for responsive behavior */
  max-width: 300px; /* Limit the width of each card */
  box-sizing: border-box; /* Include padding in width calculation */
  margin-bottom: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.benefit-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.5);
}


.benefit-icon img {
  width: 60px;
  height: 60px;
  background-color: #ffc107;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 20px;
}

.benefit-card-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.benefit-card-text {
  font-size: 16px;
  color: #ccc;
  line-height: 1.6;
}

/* Call to Action Section Styles */
.cta-section {
  background-color: #ffc107;
  color: #000;
  padding: 60px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-subtitle {
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.cta-title {
  color: #000;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #333;
}

/* Moving Strips */
.moving-strip {
  position: absolute;
  width: 200%;
  height: 15px;
  background-image: linear-gradient(90deg, #000 50%, #ffc107 50%);
  background-size: 40px 30px;
}

.top-strip {
  top: 0;
  animation: moveStripTop 3s linear infinite;
}

.bottom-strip {
  bottom: 0;
  animation: moveStripBottom 3s linear infinite;
}

@keyframes moveStripTop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-80px);
  }
}

@keyframes moveStripBottom {
  from {
    transform: translateX(-80px);
  }
  to {
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-section {
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }

  .about-content {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .about-image-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .trust-badge {
    position: relative;
    top: auto;
    left: auto;
    margin-top: 20px;
  }

  .benefit-cards {
    flex-direction: column;
    gap: 20px;
  }

  .benefit-title, .cta-title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 30px 15px;
  }

  .about-title {
    font-size: 32px;
  }

  .about-text {
    font-size: 16px;
  }

  .about-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .benefit-title, .cta-title {
    font-size: 28px;
  }

  .benefit-card {
    width: 100%;
    padding: 20px;
  }
  
  .benefit-card-title {
    font-size: 20px;
  }

  .benefit-card-text {
    font-size: 14px;
  }
  
  .cta-section {
    padding: 40px 15px;
  }
  
  .cta-button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .trust-badge {
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    padding: 15px 30px; /* Increased padding for a larger badge */
    background-color: #ffcc00;
    color: #000;
    border-radius: 50%;
    font-size: 20px; /* Increased font size for better readability */
    font-weight: bold;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for better visual impact */
    z-index: 2;
    margin-top: -60px; /* Adjusted margin to position the larger badge over the image */
  }
  
}

@media (max-width: 480px) {
  .about-title {
    font-size: 28px;
  }

  .benefit-title, .cta-title {
    font-size: 24px;
  }

  .benefit-card {
    width: 100%;
    padding: 15px;
  }

  .benefit-card-title {
    font-size: 18px;
  }

  .benefit-card-text {
    font-size: 14px;
  }

  .benefit-icon img {
    width: 50px;
    height: 50px;
  }

  .cta-button {
    font-size: 14px;
    padding: 10px 20px;
  }
  
  .about-content {
    text-align: left;
  }
}
