/* Background and layout styling */
.privacy-layout {
    background-color: #1c1c1c; /* Darker background for better contrast */
  }
  
  .privacy-content {
    padding: 40px 20px;
    max-width: 900px; /* Slightly wider content area */
    margin: 20px auto;
    background-color: #f5f5f5; /* Slightly lighter gray for content */
    border-radius: 12px; /* Softer rounded corners */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow for better depth */
  }
  
  /* Typography styling */
  .privacy-title {
    color: #000000 !important; /* Black color for the main title */
    text-align: center;
    font-weight: 700; /* Bolder title for emphasis */
    font-size: 32px; /* Larger title */
    margin-bottom: 20px; /* More space below the title */
  }
  
  .privacy-subtitle {
    margin-top: 40px; /* More space above subtitles */
    color: #ffcc00; /* Yellow color for section titles */
    font-weight: 600; /* Bolder section titles */
  }
  
  .privacy-paragraph {
    font-size: 18px; /* Slightly larger font size */
    line-height: 1.8; /* Increased line height for readability */
    color: #000000; /* Black color for text */
  }
  
  .privacy-list {
    padding-left: 30px; /* More padding for list items */
    color: #000000; /* Black color for list items */
    font-size: 18px; /* Consistent font size */
  }
  
  /* Link styling */
  .privacy-link {
    color: #ffcc00; /* Yellow color for links */
    text-decoration: underline;
  }
  
  .privacy-link:hover {
    text-decoration: none; /* Remove underline on hover */
  }
  