.booking-form-container {
  background: #000;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #ffcc00;
  margin: 10px;
}

.booking-form-title {
  color: #ffcc00;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.booking-form-tabs .ant-tabs-tab {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.booking-form-tabs .ant-tabs-tab:hover {
  color: #ffffff;
}

.booking-form-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffcc00;
}

.booking-form-tabs .ant-tabs-ink-bar {
  background: #ffcc00;
}

.trip-type {
  display: flex;
  justify-content: space-between;
}

.trip-type .ant-radio-button-wrapper {
  flex: 1;
  text-align: center;
  /* font-size: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.trip-type .ant-radio-button-wrapper-checked {
  background-color: #ffcc00;
  color: #000;
}

.booking-form {
  background: #333;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.booking-form:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
}

.booking-form-label {
  color: #ffffff;
  /* font-size: 18px; Increased font size for larger labels */
  font-weight: bold; /* Optionally make the font bold for more emphasis */
  padding: 1px;
}

.booking-form-input {
  background-color: #f7f7f7;
  color: #000;
  margin-bottom: 8px;
}

.booking-form-input .ant-input,
.booking-form-input .ant-picker-input > input {
  background-color: #f7f7f7;
  color: #000;
}

.booking-form-input .ant-input:focus,
.booking-form-input .ant-input:hover,
.booking-form-input .ant-picker-input > input:focus,
.booking-form-input .ant-picker-input > input:hover {
  border-color: #ffcc00;
}

.booking-form-button {
  background-color: #ffcc00;
  border: none;
  color: #000;
  font-weight: bold;
  padding: 12px 16px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 8px;
}

.booking-form-button:hover,
.booking-form-button:focus {
  background-color: #e6b800;
  color: #000;
}


/* Align error message to the left and below the input fields */
.ant-form-item-explain-error {
  text-align: left; /* Align the error message to the left */
  margin-top: 4px; /* Adjust margin to move it closer to the input field */
  font-size: 14px; /* Optional: Adjust font size if needed */
  color: #ff4d4f; /* Optional: Customize the error color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .booking-form {
    padding: 12px;
    margin: 10px;
  }

  .booking-form-label {
    font-size: 16px; /* Adjusted font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .booking-form {
    margin: 5px;
  }

  .booking-form-label {
    font-size: 14px; /* Further adjust font size on very small screens */
  }

  .booking-form-button {
    font-size: 14px; /* Adjust button font size on very small screens */
  }
}

.itinerary-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.stop-tag-wrapper {
  position: relative;
  margin: 8px;
}

.stop-tag {
  background-color: #ffcc00;
  color: #000;
  border: none;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.stop-tag:hover {
  overflow: visible;
  white-space: normal;
  max-width: none;
  z-index: 1;
  background-color: #ffcc00;
  padding: 8px 12px;
}

.arrow-icon {
  margin-left: 8px;
  margin-right: 8px;
}

.stop-tag .ant-tag-close-icon {
  color: #000;
}

.add-stop-button {
  margin-top: 8px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.add-stop-button:hover {
  background-color: #40a9ff;
}

.arrow-icon {
  margin: 0 8px;
  color: #ffcc00;
  font-size: 16px;
}

.header-content .round-trip-checkbox {
  color: #eeeeee;
  margin-bottom: 10px;
  align-items: left !important;
}

#add-more-city-button {
  background-color: #ffcc00;
  border: none;
  color: #000;
  font-weight: bold;
  padding: 12px 16px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 8px;
}

#add-more-city-button:hover {
  background-color: #40a9ff;
  color: white;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .mobile-navbar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    background: #fff;
    z-index: 1000;
    overflow: auto;
  }
}

/* Update navbar styling for desktop views to hide the mobile-specific style */
@media (min-width: 769px) {
  .navbar {
    display: block;
    position: static;
    width: auto;
  }
}
