.referral-system {
    background-color: #1a1a1a;
    color: #ffc107;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  
  .copy-code-btn {
    margin-top: 10px;
    background-color: #ffc107;
    color: #000;
  }
  
  .referral-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #333;
    color: #ccc !important;
  }
  
  .referral-item p {
    margin: 0;
  }
  