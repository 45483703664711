/* General Styles */
.route-detail-page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #1a1a1a;
  text-align: center;
}

/* Route Info */
.route-info {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
}

.route-info p {
  margin: 0;
}

/* Section Heading */
.top-routes-section h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #1a1a1a;
  text-align: center;
}

/* Route List */
.route-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.route-list-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.route-details {
  flex: 2;
}

.route-title {
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 10px;
  font-weight: bold;
}

.route-meta {
  font-size: 14px;
  color: #555555;
  margin-bottom: 10px;
}

/* Cab Pricing Section */
.cab-pricing {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.book-now-button {
  background-color: #ffbb33;
  color: #333;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-now-button:hover {
  background-color: #e6a800;
}

/* Scrollable Container */
.top-routes-section-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.top-routes-section {
  width: 48%;
  max-height: 300px; /* Adjust this value based on your design needs */
  overflow-y: auto;
  padding-right: 10px; /* Prevent content from being hidden by the scrollbar */
}

/* Scrollbar Styling */
.top-routes-section::-webkit-scrollbar {
  width: 8px;
}

.top-routes-section::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.top-routes-section::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.top-routes-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-routes-section-container {
    flex-direction: column;
  }

  .top-routes-section {
    width: 100%;
    max-height: none;
    margin-bottom: 20px;
  }

  .route-list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cab-pricing {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .book-now-button {
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .route-title {
    font-size: 16px;
  }

  .price {
    font-size: 14px;
  }

  .book-now-button {
    padding: 5px 8px;
    font-size: 12px;
  }
}


.journey-details-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.journey-details-card h2 {
  font-size: 22px;
  color: #333333;
  margin-bottom: 15px;
}

.journey-details-card table {
  width: 100%;
  border-collapse: collapse;
}

.journey-details-card table th,
.journey-details-card table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.journey-details-card table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333333;
}

.journey-details-card table td {
  font-size: 16px;
  color: #555555;
}

.journey-details-card table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.journey-details-card table tr:hover {
  background-color: #f1f1f1;
}

.journey-details-card table tr td:first-child {
  font-weight: bold;
  color: #777777;
}

.journey-details-card table td:last-child {
  color: #333333;
  font-weight: normal;
}
