.cities-layout {
  background: #ffc107;
  color: #fbfbfb; /* Off-white text */
}

.cities-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333333; /* Darker color for better contrast */
}

.search-bar {
  width: 100%;
  max-width: 400px; /* Reduced width for a sleeker look */
  margin: 0 auto 30px;
  display: block;
  border-radius: 20px; /* Slightly less rounded */
  background-color: #ffffff; /* Black background for contrast */
  padding: 8px 15px; /* Less padding for a more compact feel */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: none; /* Remove border for a clean look */
  transition: all 0.3s ease-in-out; /* Smooth transition for interactions */
}

.ant-input-affix-wrapper {
  border-radius: 20px !important; /* Match the search bar's rounded style */
  background-color: #fffefe; /* Black background to match the search bar */
  color: #7c7c7c; /* White text color for contrast */
  border: none; /* Remove border */
  display: flex;
  align-items: center; /* Center icon and input vertically */
}

.ant-input-affix-wrapper input {
  background-color: transparent; /* Transparent for seamless integration */
  color: #000000; /* White text color */
  font-size: 14px; /* Smaller font for a sleek look */
  padding-left: 10px; /* Padding to align with the icon */
}



.ant-input {
  border: none; /* Remove border */
  box-shadow: none; /* Remove box shadow */
}

.ant-input::placeholder {
  color: #aaaaaa; /* Lighter placeholder text for contrast */
}

