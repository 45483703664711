body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container, .row, .col { /* If using Bootstrap or similar frameworks */
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.header-layout {
  background: linear-gradient(135deg, #FFC94D, #FFB03B); /* Gradient background */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  padding: 60px 20px; /* Increased padding for better spacing */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adjust padding for mobile */
@media (max-width: 767px) {
  .header-layout {
    padding: 0;
  }
  .header-content:nth-child(n + 2) { /* Hides all but the first .header-content */
    display: none;
  }
}

.header-content {
  max-width: 1000px;
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: #ffffff;
}


.header-title {
  font-size: 54px;
  margin-bottom: 16px;
  padding: 10px 0;
  color: #ffffff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
  animation: fadeInUp 1s ease-in-out; /* Subtle fade-in animation */
}



.header-description {
  font-size: 18px;
  margin-bottom: 32px;
  color: #000000; /* White text for contrast */
}

/* Hide elements on mobile */
@media (max-width: 767px) {
  .header-title,
  .header-description {
    display: none;
  }
}

.form-col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.info-col {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  width: 100%;
}

.booking-form {
  background: #000000;
  padding: 20px;
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  width: 100%; 
  max-width: 100%; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-title {
    font-size: 32px;
  }

  .header-description {
    font-size: 16px;
  }
}



/* Floating Buttons */
.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.floating-button {
  width: 56px;
  height: 56px;
  background-color: #25d366; /* WhatsApp green for WhatsApp button */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.floating-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.floating-button.whatsapp {
  background-color: #25d366;
}

.floating-button.phone {
  background-color: #FFB03B; /* Your brand's primary color for the phone button */
}

.floating-button a {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

/* Only show floating buttons on mobile */
@media (min-width: 768px) {
  .floating-buttons {
    display: none;
  }
}

.call-us-section {
  background: #ff8c00;
  padding: 12px 24px;
  border-radius: 8px;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
  max-width: 500px;
  animation: bounceIn 1s ease;
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.call-us-section:hover {
  background: #ff8c00; /* Slightly darker orange on hover */
}

.call-us-icon {
  font-size: 20px; /* Smaller icon */
  margin-right: 8px;
  color: #000000 !important;

}

.call-us-heading {
  font-size: 16px; /* Reduced font size */
  font-weight: 600;
  margin-bottom: 0;
  color: #000000 !important;

}

.call-us-number {
  font-size: 14px; /* Smaller font size */
  font-weight: bold;
  color: #000000 !important;
  text-decoration: none;
  padding: 0 8px;
}
