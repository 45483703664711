.customer-reviews {
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 15px; /* Add some horizontal padding for mobile */
  background-color: #000000;
}

.review-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.review-card {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.review-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 20px auto;
}

.review-comment {
  font-size: 16px;
  margin: 10px 0;
}

.review-name {
  font-size: 14px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  margin: 0 4px;
}

.customer-reviews .customer-reviews-title {
  padding: 20px;
  text-align: center;
  color: white;
}

/* Add media queries for mobile */
@media (max-width: 768px) {
  .review-card {
    max-width: 90%;
    margin: 0 auto;
  }

  .review-comment {
    font-size: 14px;
  }

  .review-name {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .review-photo {
    width: 60px;
    height: 60px;
  }

  .review-comment {
    font-size: 13px;
  }

  .review-name {
    font-size: 11px;
  }
}
