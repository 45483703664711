.benefits-section {
    /* padding: 40px; */
    /* background-color: #fffbe6; Light yellow background */
    text-align: center;
  }
  
  .benefits-title {
    margin-bottom: 40px;
    color: #ffffff !important; /* Ensure the title text is white */
    font-size: 24px;
    font-weight: bold;
    line-height: 1.333;
    padding-bottom: 20px;
  }


  .benefit-card {
    background-color: #000; /* Black background for the card */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Updated shadow for better contrast */
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }

  
  
  /* Yellow background for the card body */
  .benefit-card .ant-card-body {
    background-color: #000000; /* Light yellow background */
    padding: 24px;
    border-radius: 0 0 8px 8px;
  }
  
  .benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(227, 212, 72, 0.683);
  }
  
  .benefit-card .ant-typography-title {
    color: #ffffff; /* Black text color */
  }
  
  .benefit-card .ant-typography {
    color: #ffffff; /* Black text color */
  }
  
  .vehicle-card {
    text-align: center;
    background-color: #fffbe6; /* Yellow background */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .vehicle-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .vehicle-card .vehicle-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background-color: #fff2cc; /* Lighter yellow background */
  }
  
  .vehicle-card .car-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .vehicle-card .ant-card-body {
    padding: 20px;
  }
  
  .vehicle-card .price-title {
    color: #1890ff;
    font-weight: bold;
  }
  
  .vehicle-card .vehicle-type {
    color: #000;
    font-size: 16px;
  }
  
  .vehicle-card .vehicle-description {
    color: #555;
  }
  
  .vehicle-card .vehicle-details {
    color: #333;
  }
  
  .vehicle-card .ant-btn-primary {
    background-color: #ffa500;
    border-color: #ffa500;
    font-weight: bold;
  }
  
  .vehicle-card .ant-btn-primary:hover {
    background-color: #cc8400;
    border-color: #cc8400;
  }
  
  .book-now-button {
    background-color: #ffa500;
    border-color: #ffa500;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
  }
  
  .book-now-button:hover {
    background-color: #cc8400;
    border-color: #cc8400;
  }
  
  @media (max-width: 768px) {
    .date-section,
    .features-section {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
    }
  
    .vehicle-card {
      flex-basis: 100%;
      margin: 10px 0;
    }
  
    .date-item,
    .feature-item {
      flex: 0 0 auto;
    }
  }
  

  .icon-wrapper {
    font-size: 48px; /* Increase the size of the icons */
    color: #ffffff; /* White icon color */
    margin-bottom: 20px; /* Space between icon and title */
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .benefit-card:hover .icon-wrapper {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }
  