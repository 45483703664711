.register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .register-form-button {
    width: 100%;
    height: 40px; /* Ensuring touch friendliness */
    background-color: #1890ff;
    border-color: #1890ff;
    margin-top: 20px;
  }
  
  .site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
  }
  