.services-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #000000; /* Black background */
  }
  
  .services-title {
    margin-bottom: 40px;
    color: #ffffff !important;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.333;
  }
  
  .service-card {
    background-color: #000; /* Black background for the card */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card .ant-card-body {
    background-color: #000000;
    padding: 24px;
    border-radius: 0 0 8px 8px;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(227, 212, 72, 0.683);
  }
  
  .service-card .ant-typography-title,
  .service-card .ant-typography {
    color: #ffffff; /* White text */
  }
  

  .services-section,
  .benefits-section {
    background-color: #181818; /* Slightly lighter black for better contrast */

    padding: 60px 30px;
    background: linear-gradient(135deg, #1a1a1a, #0d0d0d); /* Softer gradient background */
    text-align: center;
    border-radius: 25px;
    margin-bottom: 50px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  }
  
  .services-title,
  .benefits-title {
    margin-bottom: 40px;
    color: #FFD700; /* Bright yellow color */
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1.5px;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6); /* Enhanced text shadow for better visibility */
  }
  
  .service-card,
  .benefit-card {
    background-color: #181818; /* Slightly lighter black for better contrast */
    border: 1px solid #555; /* Subtle border for better card definition */
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 30px;
    margin-bottom: 20px;
  }
  
  .service-card:hover,
  .benefit-card:hover {
    transform: translateY(-12px);
    box-shadow: 0 16px 32px rgba(255, 223, 72, 0.8); /* More pronounced shadow on hover */
    border-color: #FFD700;
  }
  
  .service-card .ant-typography-title,
  .benefit-card .ant-typography-title {
    color: #FFD700; /* Consistent yellow text color */
    font-size: 24px;
    margin-bottom: 15px;
    letter-spacing: 1.2px;
    font-weight: 700;
  }
  
  .service-card .ant-typography,
  .benefit-card .ant-typography {
    color: #eeeeee; /* Slightly brighter text color for better readability */
    line-height: 1.7;
  }
  
  .service-card .ant-card-body,
  .benefit-card .ant-card-body {
    background-color: transparent;
    padding: 24px;
    border-radius: 0 0 8px 8px;
  }
  
  .icon-wrapper {
    font-size: 48px; /* Increase the size of the icons */
    color: #ffffff; /* White icon color */
    margin-bottom: 20px; /* Space between icon and title */
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .service-card:hover .icon-wrapper {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }
  