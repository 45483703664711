.breadcrumbs {
    /* margin: 20px 0; */
    background-color: #333;
    padding: 10px 20px;
    /* border-radius: 8px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .breadcrumb-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: #ffbb33;
  }
  
  .breadcrumb-item::after {
    content: '>';
    margin-left: 8px;
    color: #ffbb33;
  }
  
  .breadcrumb-item:last-child::after {
    content: '';
    margin-left: 0;
  }
  
  .breadcrumb-item a {
    color: #ffbb33;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .breadcrumb-item a:hover {
    color: #e6a800;
  }
  
  .breadcrumb-item.active {
    font-weight: bold;
    color: #fff;
  }
  
  .breadcrumb-item.active::after {
    color: transparent;
  }
  