.home-layout {
    min-height: 100vh;
    background-image: url('https://example.com/background.jpg'); /* Replace with your background image URL */
    background-size: cover;
    background-position: center;
  }
  
  .home-header {
    background: rgba(0, 0, 0, 0.6);
    padding: 60px 20px;
    color: #fff;
  }
  
  .header-description {
    font-size: 18px;
    margin-bottom: 32px;
  }


  .product-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .home-content {
    padding: 50px 20px;
    text-align: center;
    background-color: #FFD151;
  }

  .home {
        background-image: url('../assets/image.jpeg');
  }
  

  
  .benefit {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-footer {
    background: #001529;
    color: #fff;
    padding: 50px 20px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-footer {
    background-color: #001529;
    color: #ffffff;
    padding: 40px 20px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-content a:hover {
    color: #1890ff;
  }
  
  
  .footer-bottom {
    margin-top: 20px;
  }
  
  .footer-bottom .ant-typography {
    color: #ffffff;
  }
  
  .footer-bottom .ant-typography {
    color: #ffffff;
  }
  
  /* Custom style for the contact section */
  .footer-content {
    color: #e4dd11; /* Ensure all text is white */
    
  }

  .footer-content .ant-typography-title {
    color: #ffcc00; /* Make the title yellow */
  }
  
  .footer-content .ant-typography,
  .footer-content .ant-typography a {
    color: #ffffff;
  }
  
  .footer-content .footer-title {
    color: #ffcc00;
  }

  .title {
    color: #ffcc00;
  }

  .home-footer .ant-typography a {
    color: #ffffff; /* White color for footer links */
    display: flex;
    align-items: center;
  }
  
  .home-footer .ant-typography a .anticon {
    margin-right: 8px; /* Spacing between icon and text */
  }
  
  .home-footer .ant-typography a:hover {
    color: #ffd700; /* Yellow color on hover */
  }
  
  .home-footer .footer-title {
  color: #ffd700; /* Yellow color for footer section titles */
}

.home-footer .footer-content a {
  color: #ffffff; /* Default white color for links */
  display: flex;
  align-items: left;
}

.home-footer .footer-content a:hover {
  color: #ffd700; /* Yellow color on hover */
}

.home-footer .footer-content .anticon {
  margin-right: 8px; /* Spacing between icon and text */
}

.home-footer .footer-bottom {
  margin-top: 20px;
}

  