.payment-failure-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  }
  
  .payment-failure-card {
    max-width: 600px;
    width: 100%;
    text-align: center;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .failure-icon {
    font-size: 48px;
    color: #ff4d4f;
    margin-bottom: 20px;
  }
  
  .failure-title {
    margin-bottom: 20px;
  }
  
  .failure-message {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .transaction-details {
    text-align: left;
    margin-bottom: 30px;
  }
  
  .retry-button {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .contact-support-button {
    width: 100%;
    background-color: #ffa500;
    border-color: #ffa500;
    font-weight: bold;
  }
  
  .contact-support-button:hover {
    background-color: #cc8400;
    border-color: #cc8400;
  }
  