.past-rides {
  max-width: 900px;
  margin: 20px auto;
  padding: 0 20px;
  background-color: #f9f9f9;
}

.page-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.ride-card {
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.ride-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ride-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.ride-date {
  font-size: 18px;
  font-weight: 600;
  color: #555;
}

.ride-details {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.ride-status {
  font-size: 14px;
  border-radius: 15px;
  padding: 5px 10px;
}

.ride-action {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.details-button {
  background-color: #ffbb33;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.details-button:hover {
  background-color: #ff9f00;
  transform: scale(1.05);
}

@media (max-width: 576px) {
  .ride-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .ride-action {
    margin-top: 10px;
    text-align: center;
    justify-content: center;
  }

  .details-button {
    width: 100%;
  }
}
