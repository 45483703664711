/* BlogPostItem.css */
.blog-post-item {
  background-color: #2b2b2b;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
}

.blog-post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.blog-post-item img {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 2px solid #ffbb33;
}

.blog-post-item-content {
  /* padding: 20px; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}

.blog-post-item-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffbb33;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-post-item-content p {
  font-size: 16px;
  color: #ffeeee;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
