.home-footer {
  background-color: #000000 !important; /* Retain black background */
  color: #fff; /* Retain white text */
  padding: 40px 20px; /* Increase padding for better spacing */
}

.footer-content {
  max-width: 1200px; /* Limit the width of the content */
  margin: 0 auto; /* Center the content */
  display: flex; /* Use flexbox for alignment */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between; /* Distribute space between items */
}

.footer-column {
  flex: 1;
  min-width: 180px; /* Minimum width for smaller screens */
  margin-bottom: 20px; /* Space between stacked items on smaller screens */
  text-align: left; /* Left align text by default */
}

.footer-title {
  color: #ffd700; /* Yellow color */
  margin-bottom: 10px;
}

.footer-column a {
  color: #fff; /* White links */
  text-decoration: none;
  font-size: 16px; /* Consistent font size for all links */
  display: flex;
  align-items: center; /* Align icons with text */
}

.footer-column a:hover {
  color: #ffd700; /* Yellow on hover */
}

.footer-column a svg {
  margin-right: 8px; /* Space between icon and text */
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Left align content */
    padding-left: 20px; /* Add some left padding for better alignment */
  }

  .footer-column {
    width: 100%; /* Full width on mobile */
    text-align: left; /* Left align text on smaller screens */
  }

  .footer-column a {
    justify-content: flex-start; /* Left align the icons and text on mobile */
  }

  .footer-column a svg {
    margin-right: 10px; /* Adjust space between icon and text */
    font-size: 18px; /* Adjust icon size for better alignment on mobile */
  }

  .footer-bottom {
    text-align: left; /* Left align footer bottom text */
    padding-left: 20px; /* Align footer bottom with the rest of the content */
  }
}
