.content-layout {
    background: #000000;
  }
  
  .home-content {
    padding: 10px;
    text-align: center;
    background-color: #000000;
  }
  
  .benefits-wrapper {
    padding: 10px 20px;
    /* background: #fff; */
  }
  
  .benefits-section {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .benefit {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .home-footer {
    background: #000000;
    color: #fff;
    /* padding: 50px 20px; */
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-footer a {
    color: #fff;
  }
  
  .home-footer a:hover {
    text-decoration: underline;
    color: yellow
  }
  
  