.booking-process-section {
    padding: 60px 20px;
    background-color: #181818; /* Dark background for contrast */
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    margin-bottom: 50px;
  }
  
  .booking-process-title {
    color: #FFD700 !important; /* Bright gold for the title */
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 1.5px;
  }
  
  .booking-step-card {
    background-color: #1a1a1a; /* Dark card background */
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    padding: 20px;
  }
  
  .booking-step-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(255, 223, 72, 0.8); /* Gold shadow on hover */
  }
  
  .icon-wrapper {
    font-size: 48px;
    color: #ffffff; /* White icon color */
    margin-bottom: 20px;
  }
  
  .booking-step-title {
    color: #FFD700 !important; /* Gold for step title */
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .booking-step-title::before {
    content: attr(data-step);
    color: #FFD700 !important;  /* Yellow for Step 1, Step 2 text */
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  
  .booking-step-description {
    color: #eeeeee; /* Light grey for readability */
    line-height: 1.6;
  }
  