/* General styles for the Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #000000; /* Modern yellow */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo a {
  color: #febe2a;
  font-size: 24px;
  font-weight: bold;
  transition: color 0.3s;
}

.logo a:hover {
  color: #333;
}

.menu {
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
}

/* Override the default Ant Design border-bottom color */
.menu :where(.ant-menu-item):hover::after,
.menu :where(.ant-menu-submenu-title):hover::after,
.menu :where(.ant-menu-item-active)::after,
.menu :where(.ant-menu-submenu-active)::after,
.menu :where(.ant-menu-item-open)::after,
.menu :where(.ant-menu-submenu-open)::after {
  border-bottom-color: transparent !important; /* Remove the blue border */
}

.menu > .ant-menu-item {
  color: #ffffff;
  transition: background-color 0.3s, color 0.3s;
}

.menu > .ant-menu-item a {
  color: inherit;
  transition: color 0.3s;
}

.menu > .ant-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu > .ant-menu-item a:hover {
  color: #ffec18;
}

.question-bar .ant-btn {
  background-color: #1890ff;
  border: none;
  font-weight: bold;
  color: white;
}

.question-bar .ant-btn:hover {
  background-color: #40a9ff;
}

.menu-icon {
  display: none;
  font-size: 24px;
  color: #febe2a;
  cursor: pointer;
}

.ant-drawer-body {
  background-color: #000; /* Set the drawer background to black */
}

.drawer-menu {
  background-color: #000; /* Ensure the drawer itself is black */
}

.drawer-menu .ant-menu-item {
  text-align: center;
  background-color: transparent;
  color: #ffcc00; /* Yellow text */
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.drawer-menu .ant-menu-item:hover {
  background-color: #ffcc00; /* Yellow background on hover */
  color: #000; /* Black text on hover */
}

.drawer-menu .ant-menu-item a {
  color: #ffcc00; /* Yellow text */
  transition: color 0.3s;
}

.drawer-menu .ant-menu-item a:hover {
  color: #000; /* Black text on hover */
}

.drawer-menu .ant-menu-item .ant-btn {
  background-color: #ffcc00;
  color: #000;
  border: none;
  width: 100%;
  font-weight: bold;
}

.drawer-menu .ant-menu-item .ant-btn:hover {
  background-color: #e8a412;
  color: #000000;
}

/* Styles specifically for the Login/Signup button */
.loginbutton .ant-btn {
  background-color: #000; /* Black background */
  color: #ffcc00; /* Yellow text */
  border: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.loginbutton .ant-btn:hover {
  background-color: #333; /* Darker black on hover */
  color: #ffcc00; /* Maintain yellow text */
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}
