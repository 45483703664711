.points-dashboard {
    background-color: #1a1a1a;
    color: #ffc107;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .points-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .points-value, .tier-value {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .next-tier-info {
    margin-top: 10px;
    color: #ccc;
  }
  