.challenges {
    background-color: #1a1a1a;
    color: #ffc107;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    
  }
  
  .challenge-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #333;
    
  }
  
  .challenge-info {
    flex: 1;
    margin-right: 20px;
    color: wheat;
  }
  
  .challenge-progress {
    text-align: right;
    min-width: 150px;
  }
  
  .challenge-progress p {
    margin: 0;
    color: #ccc;
  }
  