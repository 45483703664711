.pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    list-style: none;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    padding: 8px 16px;
    background-color: #ffbb33;
    color: #131212;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .page-link:hover {
    background-color: #e6a800;
  }
  