/* User Profile Layout */
.user-profile {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Profile Box */
.profile-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 100%;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  margin-right: 30px;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.profile-name {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

.profile-email {
  margin: 5px 0;
  color: #666;
}

.profile-badge {
  background-color: #ffbb33;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.edit-profile-btn {
  background-color: #ffbb33;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  margin-top: auto; /* Move button to the bottom */
}

.edit-profile-btn .anticon {
  margin-right: 5px;
}

.edit-profile-btn:hover {
  background-color: #ff9f00;
  transform: scale(1.05);
}

/* Additional Details */
.profile-details {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.detail-item {
  margin-right: 20px;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
}

.detail-item .anticon {
  margin-right: 8px;
  color: #ffbb33;
}

@keyframes slideInLeft {
  from { opacity: 0; transform: translateX(-30px); }
  to { opacity: 1; transform: translateX(0); }
}

.profile-email {
  margin: 8px 0;
  color: #777;
}

.edit-profile-btn {
  margin-top: 15px;
  background-color: #ffbb33;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 15px;
  transition: background-color 0.4s, transform 0.4s;
}

.edit-profile-btn:hover {
  background-color: #ff9f00;
  transform: scale(1.05);
}

.profile-points-card,
.profile-badges-card,
.ride-history-card,
.special-offers-card {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 25px;
  transition: transform 0.4s, box-shadow 0.4s;
}

.profile-points-card:hover,
.profile-badges-card:hover,
.ride-history-card:hover,
.special-offers-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.points-title {
  font-size: 22px;
  color: #333333;
}

.points-value {
  font-size: 42px;
  font-weight: bold;
  color: #ffbb33;
  transition: transform 0.4s;
}

.profile-points-card:hover .points-value {
  transform: scale(1.12);
}

.points-progress {
  margin-top: 12px;
  font-size: 15px;
  color: #888888;
}

.badges-title,
.ride-history-title,
.special-offers-title {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 18px;
}

.badges-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.badge-wrapper {
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.4s;
}

.badge-wrapper:hover {
  transform: scale(1.15);
}

.badge-name {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  color: #777777;
}

.offers-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.4s;
}

.offers-icon {
  font-size: 40px;
  color: #ffbb33;
  margin-bottom: 12px;
}

.special-offers-description {
  font-size: 17px;
  color: #999999;
  text-align: center;
}

.special-offers-card:hover .offers-content {
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-profile {
    padding: 18px;
  }

  .profile-name {
    font-size: 26px;
  }

  .points-title,
  .badges-title,
  .ride-history-title,
  .special-offers-title {
    font-size: 20px;
  }

  .points-value {
    font-size: 38px;
  }

  .badge-name {
    font-size: 14px;
  }

  .special-offers-description {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .profile-name {
    font-size: 22px;
  }

  .points-value {
    font-size: 34px;
  }

  .points-progress {
    font-size: 14px;
  }

  .badges-title,
  .ride-history-title,
  .special-offers-title {
    font-size: 18px;
  }

  .badge-name {
    font-size: 12px;
  }
}

.badge-wrapper {
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.4s, color 0.4s;
  font-size: 24px; /* Adjust icon size */
}

.badge-wrapper:hover {
  transform: scale(1.15);
  color: #ffbb33; /* Highlight color on hover */
}

.badge-name {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  color: #777777;
}

.offers-icon {
  font-size: 36px;
  color: #ffbb33;
  margin-bottom: 10px;
  transition: transform 0.4s, color 0.4s;
}

.offers-icon:hover {
  transform: scale(1.1);
  color: #ff9f00; /* Change icon color on hover */
}
