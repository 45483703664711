.city-page {
  padding: 20px;
  background-color: #000000;
  font-family: 'Arial', sans-serif;
  color: #f0f0f0;
}

.city-intro {
  text-align: center;
  margin-bottom: 30px;
}

.city-intro h1 {
  color: #FFD700;
  font-size: 36px;
  margin-bottom: 10px;
}

.city-intro p {
  color: #ffffff;
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

.city-history,
.city-culture,
.services-section,
.routes-section,
.faqs-section {
  margin-bottom: 40px;
  padding: 30px;
  border-radius: 10px;
  background-color: #211f1f;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.city-history h2,
.city-culture h2,
.services-section h2,
.routes-section h2,
.faqs-section h2 {
  color: #FFD700;
  font-size: 28px;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.city-history p,
.city-culture p,
.services-section p,
.routes-section p,
.faqs-section p {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 10px;
}

.service-card,
.route-card,
.faq-card {
  background-color: #252323;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover,
.route-card:hover,
.faq-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3,
.route-card h3,
.faq-card h3 {
  color: #FFD700;
  font-size: 22px;
  margin-bottom: 10px;
}

.service-card p,
.route-card p,
.faq-card p {
  color: #fff;
  font-size: 16px;
}

.cta-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #FFD700;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e5c100;
}

.book-now-button {
  display: block;
  background-color: #FFD700;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-now-button:hover {
  background-color: #e5c100;
}

@media (max-width: 768px) {
  .city-intro h1 {
      font-size: 28px;
  }

  .city-intro p,
  .city-history p,
  .city-culture p,
  .services-section p,
  .routes-section p,
  .faqs-section p {
      font-size: 14px;
  }

  .service-card h3,
  .route-card h3,
  .faq-card h3 {
      font-size: 20px;
  }
}

.city-page h2, .city-page h3 {
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.city-history p, .city-culture p {
  color: #ffffff;
  text-align: left;
  margin-bottom: 20px;
}

.city-history ul, .city-culture ol {
  margin-left: 20px;
  color: #ffffff;
  text-align: left;
}

.city-history .topic-headings, .city-culture .topic-headings {
  text-align: center;
  margin-bottom: 30px;
}

.city-intro p {
  color: #ffffff;
}


.service-card, .route-card {
  background-color: #252323;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover, .route-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(233, 233, 21, 0.783);
}

.service-card h3, .route-card h3 {
  color: #FFD700;
  font-size: 22px;
  margin-bottom: 10px;
}

.icon-wrapper {
  font-size: 24px;
  color: #FFD700;
  margin-bottom: 10px;
  text-align: center;
}

.routes-section {
  text-align: center;
}
