.faq-section {
    padding: 60px 20px;
    background-color: #181818; /* Dark background to match theme */
    text-align: left;
    /* border-radius: 15px; Consistent rounded corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  }
  
  .faq-title {
    color: #FFD700 !important; /* Bright gold for FAQ title */
    text-align: center;
    margin-bottom: 30px;
    font-size: 32px; /* Larger font for emphasis */
    font-weight: 800; /* Bold title */
    letter-spacing: 1.5px; /* Spaced out letters */
  }
  
  .ant-collapse-header {
    font-size: 20px; /* Slightly larger font for questions */
    color: #ffffff !important; /* White text for headers */
    background-color: #1a1a1a; /* Darker background for headers */
    border-radius: 8px; /* Rounded corners for headers */
    padding: 15px; /* Add padding for spacing */
    transition: background-color 0.3s ease;
    /* color: #FFD700 !important; Bright gold for FAQ title */
  }
  
  .ant-collapse-header:hover {
    background-color: #FFD700; /* Gold highlight on hover */
    color: #000000 !important; /* Black text on hover for contrast */
  }
  
  .ant-collapse-content {
    background-color: #1a1a1a; /* Match the header background */
    border-top: 1px solid #333; /* Border to separate sections */
  }
  
  .ant-collapse-content p {
    font-size: 16px;
    color: #000000 !important; /* Black text on hover for contrast */
    padding: 15px 20px; /* Padding for readability */
  }
  
  .ant-collapse-item {
    border-bottom: 1px solid #333; /* Border between items */
  }
  
  .ant-collapse-item:last-child {
    border-bottom: none; /* Remove bottom border from last item */
  }
  