.scratch-card {
    background-color: #ffeb3b;
    color: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .scratch-card:hover {
    transform: translateY(-5px);
  }
  
  .scratch-btn {
    background-color: #333;
    color: #ffc107;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .scratch-result h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .scratch-result p {
    font-size: 1.2em;
    font-weight: bold;
  }
  