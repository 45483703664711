.redeemable-rewards {
    background-color: #1a1a1a;
    color: #ffc107;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  
  .reward-item {
    background-color: #333;
    padding: 20px;
    color: aliceblue !important;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .reward-info {
    margin-bottom: 10px;
  }
  
  .redeem-btn {
    background-color: #ffc107;
    color: #000;
  }
  
  .redeem-btn:disabled {
    background-color: #555;
    color: #ffffff;
  }
  