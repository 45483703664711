.payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.payment-success-card {
  max-width: 600px;
  width: 100%;
  text-align: center;
  padding: 40px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.payment-success-card:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.success-icon {
  font-size: 64px;
  color: #52c41a;
  margin-bottom: 20px;
}

.success-title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.success-message {
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;
}

.transaction-details {
  text-align: left;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

.google-login-section {
  margin-bottom: 20px;
}

.login-prompt {
  font-size: 16px;
  margin-bottom: 10px;
  color: #444;
}

.google-login-button {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  max-width: 280px;
  height: 40px;
}

.back-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button:hover {
  background-color: #40a9ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
